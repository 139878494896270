<template>
  <div class="tw-p-6 sm:tw-p-10">
    <div class="tw-block sm:tw-flex tw-mb-6 tw-items-center tw-justify-between">
      <h5 class="tw-text-2xl inter-semibold tw-text-black tw-mb-3 sm:tw-mb-0">
        Daftar Administrator
      </h5>
      <div>
        <Button
          label="Tambah"
          icon="pi pi-plus"
          class="tw-h-9 tw-text-sm tw-mb-3 sm:tw-mb-0 tw-bg-transparent tw-text-white tw-hover:tw-bg-neutral-300 tw-border-blue-light tw-bg-blue-light"
          @click="navigateToCreate"
        />
      </div>
    </div>

    <form @submit.prevent="doSearch()" class="tw-mb-8 tw-flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText
          v-model="keyword"
          placeholder="Cari disini"
          class="tw-h-11 md:tw-w-80 tw-w-auto mr-3"
        />
      </span>
      <Button label="Filter" 
        type="submit"
        icon="pi pi-align-center" 
        iconPos="left" 
        class="tw-h-11 tw-text-sm tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300 tw-block md:tw-hidden" 
      />
    </form>

    <div class="tw-hidden sm:tw-block">
      <DataTable
        :value="data"
        :paginator="true"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PageLinks LastPageLink"
      >
        <template #empty>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
            Tidak ada data yang ditemukan
          </div>
        </template>
        <template #loading>
          <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
            Loading records, please wait...
          </div>
        </template>
        <Column field="no" header="No." sortable style="min-width: 2rem"></Column>
        <Column header="Email" field="email" sortable style="min-width: 12rem"></Column>
        <Column header="No. Hp" field="phone_number" sortable style="min-width: 12rem"></Column>
        <Column header="Status" field="status" sortable style="min-width: 11rem">
          <template #body="{ data }">
            <Tag class="status status-warning" value="InActive" v-if="data.status === 0"></Tag>
            <Tag class="status status-info" value="Active" v-else-if="data.status === 1"></Tag>
            <Tag class="status status-danger" value="Suspend" v-else-if="data.status === 2"></Tag>
          </template>
        </Column>
        <Column header="Aksi" style="min-width: 8rem">
          <template #body="{ data }">
            <a :href="`${data.id}`" class="tw-mr-4" @click.prevent="detailData(data.id)">
              <i class="pi pi-eye tw-text-lg"></i>
            </a>
            <a :href="`${data.id}`" class="tw-mr-4" @click.prevent="editData(data.id)">
              <i class="pi pi-pencil tw-text-lg"></i>
            </a>
            <a :href="`${data.id}`" class="tw-mr-4" @click.prevent="confirmResetPassword(data)">
              <i class="pi pi-refresh tw-text-lg"></i>
              Reset Password
            </a>
          </template>
        </Column>
      </DataTable>
    </div>

    <div class="tw-block sm:tw-hidden">
      <div class="card tw-p-0 tw-mb-5" v-for="(item, i) in card_data" :key="`item_${i}`">
        <div class="tw-px-5 tw-py-3">
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize tw-mb-3 tw-pt-1">{{ item.email }}</h6>
            <a href="#" @click.prevent="toogle($event, item.id)">
              <i class="pi pi-ellipsis-v"></i>
            </a>
            <Menu ref="menu" 
              id="overlay_menu" 
              :model="[
                {
                  label: 'Lihat Detail',
                  icon: 'pi pi-eye',
                  command: () => {
                    this.detailData(item.id)
                  }
                },
                {
                  label: 'Edit',
                  icon: 'pi pi-pencil',
                  command: () => {
                    this.editData(item.id)
                  }
                },
                {
                  label: 'Reset Password',
                  icon: 'pi pi-refresh',
                  command: () => {
                    this.confirmResetPassword(item)
                  }
                },
              ]" 
              :popup="true"
            />
          </div>
          <hr class="mb-3">
          <div class="tw-flex tw-justify-between tw-mb-1">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">No. Hp</h6>
            <h6 class="tw-capitalize opacity-60 tw-text-sm">{{ item.phone_number }}</h6>
          </div>
          <div class="tw-flex tw-justify-between">
            <h6 class="tw-capitalize opacity-60 tw-text-sm tw-mr-3">Status</h6>
            <div>
              <Tag class="status status-warning" value="InActive" v-if="item.status === 0"></Tag>
              <Tag class="status status-info" value="Active" v-else-if="item.status === 1"></Tag>
              <Tag class="status status-danger" value="Suspend" v-else-if="item.status === 2"></Tag>
            </div>
          </div>
        </div>
      </div>

      <Paginator :rows="limit" 
        :totalRecords="data.length" 
        @page="changePage"
        template="FirstPageLink PageLinks LastPageLink">
      </Paginator>
    </div>
  </div>

  <Dialog header="Reset Password" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
    <div class="flex align-items-center justify-content-center">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>Apakah Anda yakin ingin melanjutkan reset password?</span>
    </div>
    <template #footer>
      <template v-if="!loadingResetPassword">
        <Button label="Tidak" icon="pi pi-times" @click="closeConfirmation" class="p-button-text"/>
        <Button label="Ya" icon="pi pi-check" @click="doResetPassword(store.id)" class="p-button-text" autofocus/>
      </template>
      <ProgressSpinner style="width: 25px; height: 25px;" strokeWidth="8" v-else />
    </template>
  </Dialog>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      keyword: '',
      offset: 0,
      limit: 10,
      users: [],
      data: [],
      card_data: [],
      current_id: null,
      menus: [
        {
          label: 'Lihat Detail',
          icon: 'pi pi-eye',
          command: () => {
            this.detailData(this.current_id)
          }
        },
      ],

      displayConfirmation: false,
      store: {},
      loadingResetPassword: false,
    };
  },
  mounted() {
    this.loadData()
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-admin" });
    },
    editData(id) {
      this.$router.push({ name: "edit-admin", params: { id: id } });
    },
    detailData(id) {
      this.$router.push({ name: "detail-admin", params: { id: id } });
    },

    loadData() {
      this.$http
        .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/admin`).then(response => {
          this.users = response.data.data.users
          this.users.forEach((item, idx) => {
            item.no = idx + 1
          })
          this.data = this.users
          this.changePage({page: 0})
        })
    },
    doSearch() {
      let keyword = this.keyword.toLowerCase()

      if (keyword === 'inactive' || keyword === 'tidak aktif') {
        this.data = this.users.filter(item => item.status === 0)
        return
      } else if (keyword === 'active' || keyword === 'aktif') {
        this.data = this.users.filter(item => item.status === 1)
        return
      } else if (keyword === 'suspend') {
        this.data = this.users.filter(item => item.status === 2)
        return
      } 

      this.data = this.users.filter(
        (item) =>
          item.email.toLowerCase().includes(keyword) ||
          item.phone_number.toLowerCase().includes(keyword)
      );
      this.card_data = this.data
      this.changePage({page: 0})
    },
    toogle(event, id) {
      this.current_id = id
      this.$refs.menu.toggle(event);
    },
    changePage(event) {
      let page = event.page + 1
      let offset = (page - 1) * this.limit
      let dataLength = this.data.length

      let breakPoint = page <= Math.floor(dataLength / this.limit)
                  ? offset + this.limit
                  : offset + (dataLength % this.limit)
      
      this.card_data = []
      for (let i = offset; i < breakPoint; i++) {
        const element = this.data[i];
        this.card_data.push(element)
      }
    },

    confirmResetPassword(data) {
      this.displayConfirmation = true
      this.store = data
    },
    doResetPassword(adminID) {
      this.loadingResetPassword = true
      this.axios.post(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/${adminID}/admin/reset-password`).then(response => {
        this.displayConfirmation = false
        this.loadingResetPassword = false
        if (response.status === 200) {
          this.$toast.add({
            severity: 'success',
            closable: true,
            summary: 'Success',
            detail: `Password yang baru telah di kirim ke email ${this.store.email}`,
          })
        } else {
          this.$toast.add({
            severity: 'error',
            closable: true,
            summary: 'Error',
            detail: response.data.message
          })
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
</style>
